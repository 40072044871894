import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { categories, ethnicities, platforms } from "../../campaigns/lists";
import { apiGet } from "../../../utils/api";
import DataTable from "../data_table";
import { Link, useSearchParams } from "react-router-dom";
import { PlatformIcon, SizeBadge } from "../../campaigns/utils";
import { Drawer } from "../../../utils/offcanvas";
import Form from "./form";
import { EllipsisMenu } from "../../../utils/ellipsis_menu";

const emptyFilters = {
  sort: "handle",
  sortDir: "asc",
  page: 1,
};

const countries = ["United States", "United Kingdom", "Mexico", "Brazil", "Canada"];

const genders = [
  { value: "Female", label: "Female" },
  { value: "Male", label: "Male" },
  { value: "Other", label: "Other" },
];

const sizes = [
  { value: "celebrity", label: "Celebrity" },
  { value: "mega", label: "Mega" },
  { value: "mid-tier", label: "Mid-tier" },
  { value: "micro", label: "Micro" },
  { value: "nano", label: "Nano" },
];

const setFiltersFromCampaign = (setFilters) => {
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaignId");

  useEffect(() => {
    if (campaignId) {
      apiGet(`/admin/campaigns/${campaignId}`).then(({ data }) => {
        const { campaign } = data;

        setFilters({
          platform: [campaign.platform],
          country: campaign.creator_locations,
          category: campaign.categories,
          sort: "handle",
          sortDir: "asc",
          page: 1,
        });
      });
    }
  }, [campaignId]);
};

const AdminCreatorsIndex = () => {
  const [filters, setFilters] = useState(emptyFilters);
  const [formObject, setFormObject] = useState<{ uuid?: string } | undefined>(undefined);

  setFiltersFromCampaign(setFilters);

  const { data, refetch, isSuccess, isFetching } = useQuery({
    queryKey: ["data", filters],
    queryFn: () => apiGet(`/admin/creators`, { params: filters }).then((res) => res.data),
    keepPreviousData: true,
  });

  const { creator_platforms, meta } = data || {};

  return (
    <div id="main">
      <div className="flex space-between align-items-center">
        <h1>Manage Creators</h1>
        <button className="btn btn-primary btn-sm" onClick={() => setFormObject({})}>
          Add Creator
        </button>
      </div>

      {isSuccess && (
        <>
          <DataTable
            schema={[
              {
                name: "name",
                value: (p) => (
                  <Link to={`/admin/creators/${p.creator.uuid}`}>
                    {p.creator.name || <span className="red">&lt;no name&gt;</span>}
                  </Link>
                ),
              },
              {
                name: "handle",
                value: (p) => (
                  <>
                    <PlatformIcon platform={p.platform} />
                    <a href={p.link} target="_blank" className="ml-2">
                      {p.handle}
                      <i className="bi bi-box-arrow-up-right gray ml-1 fs-10 vertical-align-middle" />
                    </a>
                  </>
                ),
              },
              { name: "size", value: (p) => <SizeBadge size={p.size} /> },
              { name: "followers", type: "number" },
              { name: "country", value: "creator.country" },
              { name: "categories", value: "categories" },
              { name: "gender", value: "creator.gender" },
              { name: "ethnicity", value: "creator.ethnicity" },
              {
                name: "created",
                value: (p) => new Date(p.created_at).toLocaleDateString(),
              },
              {
                name: "edited",
                value: (p) => new Date(p.updated_at).toLocaleDateString(),
              },
              {
                name: "edit",
                value: (p) => (
                  <EllipsisMenu
                    actions={[
                      {
                        label: "View Profile",
                        link: `/admin/creators/${p.creator.uuid}`,
                      },
                    ]}
                  />
                ),
                sortable: false,
              },
            ]}
            data={creator_platforms}
            filterControls={[
              { name: "search", type: "search" },
              { name: "platform", placeholder: "All Platforms", options: platforms },
              { name: "country", placeholder: "All Countries", options: countries },
              { name: "categories", placeholder: "All Categories", options: categories },
              { name: "gender", placeholder: "All Genders", options: genders },
              { name: "ethnicity", placeholder: "All Ethnicities", options: ethnicities },
              { name: "size", placeholder: "All Sizes", options: sizes },
            ]}
            filters={filters}
            setFilters={setFilters}
            reset={() => setFilters(emptyFilters)}
            sortable
            meta={meta}
            widths={[150, 200, 125, 125, 150, null, 100, 100, 100, 100, 50]}
            isFetching={isFetching}
          />
        </>
      )}

      <Form
        creator={formObject}
        onSuccess={refetch}
        close={() => setFormObject(undefined)}
      />
    </div>
  );
};

export default AdminCreatorsIndex;
