import classNames from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";
import { usePageData } from "../../utils/api";
import { Tab, Tabs } from "react-bootstrap";
import { Drawer } from "../../utils/offcanvas";
import { PlatformIcon } from "./utils";
import Faqs from "./faq";

const faqs = [
  {
    title: "How do I know which package is the best fit for my campaign?",
    body: "Each package is designed to meet different campaign goals. The more you invest, the broader the reach and engagement you can expect. We recommend considering your audience size, engagement goals, and budget when selecting a package. Our AI also fine-tuned the package to match your specific objectives.",
  },
  {
    title: "What happens after I purchase a package?",
    body: "Once you purchase a package, we will notify the creators that they have been chosen for your campaign. You’ll be updated when the creators start creating content, and you'll be able to review and approve the content before it's posted. After the creators have posted the content, you’ll receive updates on campaign performance, including analytics and key metrics.",
  },
  {
    title: "What happens if a creator doesn’t complete the campaign?",
    body: "If a creator doesn’t fulfill their commitment, our platform will automatically source a replacement from your matched pool. We ensure your campaign stays on track with no additional costs to you.",
  },
  {
    title: "What happens if my campaign doesn’t perform as predicted?",
    body: "While our AI-driven predictions are based on historical data and current market trends, actual performance can vary due to external factors. If your campaign doesn't meet the expected results, we’ll review the campaign data and provide recommendations to optimize future campaigns. Our goal is to continuously improve outcomes and help you achieve your marketing objectives.",
  },
  {
    title: "How is the cost of the package allocated?",
    body: "The package cost covers the fees for creators once they complete their tasks. Additionally, a separate transaction fee is charged for using the Xrossworld platform to manage your campaign efficiently and transparently. This transaction fee is added separately to maintain transparency.",
  },
  {
    title: "How are payments to creators handled?",
    body: "You won’t need to manage payments to creators—Xrossworld’s technology automatically handles it once the creators complete their tasks. This allows you to focus on campaign performance while we handle the logistics.",
  },
];

const PackageSelected = () => {
  const { data, unavailable } = usePageData();
  const [viewCreator, setViewCreator] = useState<any | undefined>(undefined);

  if (unavailable) {
    return unavailable;
  }

  const { packages } = data;

  return (
    <div id="main" className="container">
      <div className="mb-8 text-center">
        <div className="heading mt-5">
          <h1 className="me-auto">Campaign Packages</h1>
        </div>

        <p className="mt-3 px-35">
          Review and select one of the packages below based on your campaign needs. Once
          you've selected a package, we'll ask some final questions so the creators can
          get started. Have questions - <a href="#">contact us</a>!
        </p>
      </div>

      <Tabs>
        {packages.map((p, index) => (
          <Tab
            key={index}
            eventKey={`package-${index + 1}`}
            title={
              <>
                <strong>Package {index + 1}</strong>
                <span className="small d-block">{p.cost}</span>
              </>
            }
          >
            <Package packageItem={p} index={index + 1} setViewCreator={setViewCreator} />
          </Tab>
        ))}
      </Tabs>

      <Drawer
        show={!!viewCreator}
        onHide={() => setViewCreator(undefined)}
        title={viewCreator?.creator.name}
      >
        <>
          <div>
            {viewCreator?.creator.creator_platforms.map((p, i) => (
              <div key={i}>
                <PlatformIcon platform={p.platform} handle={p.handle} className="" />
              </div>
            ))}
          </div>

          <dl className="mt-3">
            <dt>Name</dt>
            <dd>{viewCreator?.creator?.name}</dd>

            <dt>Age</dt>
            <dd>--</dd>

            <dt>Gender</dt>
            <dd>{viewCreator?.creator?.gender}</dd>
          </dl>

          <div>
            <button
              type="button"
              className="btn btn-outline-primary mt-5 pull-right"
              onClick={() => setViewCreator(undefined)}
            >
              Close
            </button>
          </div>
        </>
      </Drawer>
    </div>
  );
};

export default PackageSelected;

const Package = ({ packageItem, index, setViewCreator }) => {
  const uuid = location.pathname.split("/")[2];

  const highlights = [
    {
      label: "Number of Creators",
      value: packageItem.creator_platforms.length,
    },
    { label: "Number of UGC", value: packageItem.ugc_count },
    { label: "Number of Posts", value: packageItem.posts_count },
    { label: "Potential Plays", value: packageItem.potential_plays },
    { label: "Potential Engagement", value: packageItem.potential_engagement },
    { label: "Internet User Cost", value: packageItem.internet_user_cost },
  ];

  return (
    <div className="mt-5">
      <div className="mb-5">
        <h4 className="mb-3 text-center">Package Highlights</h4>
        <div id="platforms" className="row row-cols-1 row-cols-md-3 g-3">
          {highlights.map(({ label, value }) => (
            <div className="col" key={label}>
              <div className="card h-100 text-center">
                <div className="card-body">
                  <div className="neon">{label}</div>
                  <h3 className="mb-0">{value}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <h4 className="mb-3 text-center">Package Creators</h4>
      <table className="table align-middle table-hover">
        <thead>
          <tr>
            <th>Creator</th>
            <th>Handle</th>
            <th className="text-right">Followers</th>
            <th>Creator Type</th>
            <th>Top Audience Gender</th>
            <th>Avg. Post Play</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {packageItem.creator_platforms.map((p, i) => (
            <tr key={p.name} className="pointer fs-14" onClick={() => setViewCreator(p)}>
              <td>
                <div>{p.name}</div>
                <div>{p.creator.name}</div>
              </td>
              <td>
                <PlatformIcon platform={p.platform} handle={p.handle} />
              </td>
              <td className="text-right">{p.followers?.toLocaleString()}</td>
              <td>{p.size}</td>
              <td>{p.audience_gender}</td>
              <td>{p.avg_post_play}</td>
              <td className="link-like">
                View
                <i className="bi bi-chevron-right" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="page-actions">
        <Link className="btn btn-neon" to={`/campaigns/${uuid}/package_selected`}>
          Select Package {index}
        </Link>
      </div>

      <Faqs faqs={faqs} />
    </div>
  );
};
