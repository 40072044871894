import classNames from "classnames";
import { Tooltip as BootsrapTooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const Tooltip = ({
  tip,
  className,
  children,
}: {
  tip: string | JSX.Element | JSX.Element[];
  className?: string;
  children: JSX.Element;
}) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={<BootsrapTooltip id="button-tooltip">{tip}</BootsrapTooltip>}
    >
      <span className={classNames("pointer", className)}>{children}</span>
    </OverlayTrigger>
  );
};

export default Tooltip;

export const QuestionTooltip = ({
  children,
  tip,
}: {
  children?: JSX.Element | JSX.Element[];
  tip: string | JSX.Element;
}) => (
  <Tooltip tip={tip || children}>
    <i className="bi bi-question-circle small text-muted ml-1" />
  </Tooltip>
);
