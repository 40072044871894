import classNames from "classnames";
import { useEffect } from "react";
import { useApiGet } from "../../utils/api";

export const campaignUuid = () => location.pathname.split("/")[2];

export const useCampaign = () => {
  const uuid = location.pathname.split("/")[2];
  const { data, unavailable, refetch } = useApiGet(`/campaigns/${uuid}/form`);

  return { campaign: data?.campaign, unavailable, refetch };
};

export const useShowOnboardingNav = () => {
  useEffect(() => {
    document
      .querySelectorAll(".campaign-menu-item")
      .forEach((e) => e.classList.remove("d-none"));
    return () => {
      document
        .querySelectorAll(".campaign-menu-item")
        .forEach((e) => e.classList.add("d-none"));
    };
  }, []);
};

export const useTemplate2 = () => {
  useEffect(() => {
    document.body.classList.replace("template-2", "template-1");
    return () => {
      document.body.classList.replace("template-1", "template-2");
    };
  }, []);
};

export const PlatformIcon = ({
  platform,
  className,
  handle,
}: {
  platform: string;
  className?: string;
  handle?: string;
}) => {
  const icon = { x: "twitter-x" }[platform] || platform;

  return (
    <div className={`platform-icon ${platform} ${className}`}>
      <i className={classNames(`bi bi-${icon}`)} />
      {handle && <span>{handle}</span>}
    </div>
  );
};

export const SizeBadge = ({ size }) => (
  <span className={`badge badge-size badge-${size}`}>{size}</span>
);
