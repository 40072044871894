import Header from "./header";
import CampaignInfo from "./steps_side_nav";
import { useCampaign, useShowOnboardingNav } from "./utils";
import {
  DateField,
  MultiSelect,
  SelectField,
  TextArea,
  TextField,
  Validators,
} from "./fields";
import ActionButtons from "./action_buttons";
import AddBrand from "./brand_form";
import { useApiGet } from "../../utils/api";
import { useFormikHandler } from "./form_handler";
import { Drawer } from "../../utils/offcanvas";
import { useState } from "react";
import Tooltip from "./tooltip";
import Faqs from "./faq";

const faqs = [
  {
    title: "What information should I include in the campaign description?",
    body: "In the campaign description, include the overall purpose of your campaign, key messages, and any creative direction you'd like the creators to follow. This helps ensure that the creators align with your vision and deliver content that meets your goals.",
  },
  {
    title: "Why do I need to select success metrics (KPIs) for my campaign?",
    body: "Selecting key performance indicators (KPIs) helps measure the success of your campaign. These metrics, such as impressions, engagement, or conversions, will guide the platform’s performance tracking and ensure that your campaign goals are met.",
  },
  {
    title: "Can I change my campaign details before I review and submit?",
    body: "Yes, you can edit your campaign details, including the campaign name, brand, success metrics, and description, before you review and submit the campaign. Once you've finalized and submitted the campaign for launch, changes may be limited.",
  },
];

const CampaignBrief = () => {
  useShowOnboardingNav();
  const { campaign, unavailable } = useCampaign();
  const brands = useApiGet("/brands", "brands");
  const [showBrandForm, setShowBrandForm] = useState(false);

  const formik = useFormikHandler({
    campaign,
    fields: [
      "name",
      "brand_id",
      "objectives",
      "about",
      "posting_start_date",
      "posting_end_date",
    ],
    validators: {
      name: Validators.required,
      brand_id: Validators.required,
      objectives: Validators.atLeastOneInGroup,
      about: Validators.required,
      posting_start_date: Validators.required,
      posting_end_date: Validators.required,
    },
  });

  if (unavailable || brands.unavailable) {
    return unavailable || brands.unavailable;
  }

  return (
    <div id="main" className="container">
      <Header percent={40} />
      <CampaignInfo campaign={campaign} />

      <div className="row">
        <div className="col">
          <div className="page-subhead mb-4">
            <h1>Campaign Brief</h1>
            <h2>
              Provide information for your campaign and the product/service you'd like to
              promote.
            </h2>
          </div>

          <form onSubmit={formik.handleSubmit} className="form-container">
            <TextField
              label="Campaign name"
              name="name"
              sublabel="Set a name that will help you differentiate campaigns"
              formik={formik}
            />

            <SelectField
              label="Brand"
              sublabel="Company or brand this campaign is for."
              name="brand_id"
              className="mt-4"
              options={[
                { value: "_add_", label: "Add new brand" },
                { value: "", label: "-------------" },
                ...(brands?.brands?.map((b) => ({ label: b.name, value: b.id })) || []),
              ]}
              formik={formik}
              placeholder=""
              onChange={(e) => {
                if (e.target.value === "_add_") {
                  setShowBrandForm(true);
                  e.target.value = "";
                }
              }}
            />

            <div className="mt-4">
              <label>Success metrics</label>
              <div className="form-text">
                KPIs / metrics for the campaign. Select top 3
                <span className="link-like ml-1">About objective options</span>
              </div>

              <MultiSelect
                options={[
                  "Impressions",
                  "Interactions",
                  "Clicks",
                  "Following growth",
                  "Ad-Worthy content",
                  "Engagement",
                  "Watch Time",
                  "Sales",
                ]}
                name="objectives"
                formik={formik}
              />
            </div>

            <div className="mt-4">
              <TextArea
                label="About the campaign"
                sublabel="Let creators know the purpose of your campaign. Include any creative direction here."
                name="about"
                placeholder="What do you want the creators to know about your campaign. Include any creative direction here."
                formik={formik}
              />
            </div>

            <div className="mb-4 col-6 d-none">
              <label>Content due for approval</label>
              <div className="form-text">Note on why some dates can't be selected.</div>

              <input type="date" className="form-control" />
            </div>

            <div className="mt-4 mb-4 col-6 row">
              <div className="col-6">
                <DateField
                  label={
                    <>
                      Posting start date
                      <Tooltip tip="Earliest date creators can post.  Note that this date is based on 7 days content production and 2 days for approvals.  Revisions may delay timeline.">
                        <i className="bi bi-question-circle small text-muted ml-1" />
                      </Tooltip>
                    </>
                  }
                  name="posting_start_date"
                  formik={formik}
                />
              </div>

              <div className="col-6">
                <DateField
                  label={
                    <>
                      Posting end date
                      <Tooltip tip="Latest date creators can post.">
                        <i className="bi bi-question-circle small text-muted ml-1" />
                      </Tooltip>
                    </>
                  }
                  name="posting_end_date"
                  formik={formik}
                />
              </div>
            </div>

            <ActionButtons />
          </form>
        </div>
      </div>

      <Faqs faqs={faqs} />

      <Drawer
        show={showBrandForm}
        onHide={() => setShowBrandForm(false)}
        title="Add Brand"
      >
        <AddBrand
          onSuccess={(newId) => {
            brands.refetch();
            formik.setFieldValue("brand_id", newId);
          }}
          close={() => setShowBrandForm(false)}
        />
      </Drawer>
    </div>
  );
};

export default CampaignBrief;
