import classNames from "classnames";
import { Link } from "react-router-dom";
import { Drawer } from "../../utils/offcanvas";

const StepsSideNav = ({ campaign }) => {
  const currentPage = location.pathname.split("/").pop();
  const currentStep = campaign.steps.find((step) => step.key === currentPage);

  const group = campaign.steps;

  return (
    <Drawer
      title=""
      trigger={
        <>
          <a className="sidebar-open" role="button">
            <i className="bi bi-list-ul"></i>
          </a>
        </>
      }
      placement="start"
    >
      <div>
        <div>
          {group.map((step, index) => (
            <div
              key={step.key}
              className={classNames("left-nav-row", {
                done: step.done,
                active: step.key === currentPage,
                next: step.next,
              })}
            >
              <div className="left-nav-row-combo">
                {index > 0 && <div className="left-nav-row-separator" />}
                <div className="left-nav-row-circle">
                  {step.done && <div className="checkmark" />}
                </div>
              </div>
              <div className="left-nav-row-text">
                {(step.done || step.next) && step !== currentStep ? (
                  <Link to={`/campaigns/${campaign.uuid}/${step.key}`}>{step.title}</Link>
                ) : (
                  step.title
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};
export default StepsSideNav;
