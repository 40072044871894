import CampaignInfo from "./steps_side_nav";
import { campaignUuid, useCampaign, useShowOnboardingNav, useTemplate2 } from "./utils";
import { merge, minBy } from "lodash";
import classNames from "classnames";
import ActionButtons from "./action_buttons";
import * as Yup from "yup";
import { useFormikHandler } from "./form_handler";
import { useApiGet } from "../../utils/api";
import Faqs from "./faq";

const range = (tuple) => tuple.map((v) => v.toLocaleString()).join(" - ");
const tierFormats = {
  nano: "Nano",
  micro: "Micro",
  midtier: "Mid-Tier",
  mega: "Mega",
  celebrity: "Celebrity",
};

const detailsSchema = [
  { label: "Number of Creators", key: "creators", format: range },
  { label: "Number of UGC", key: "ugc", format: range },
  { label: "Number of Posts", key: "posts", format: range },
  {
    label: "Range of Creator Types",
    key: "sizes",
    format: (t) => t.map((v) => tierFormats[v]).join(" - "),
  },
  { label: "Available Audience Size", key: "followers", format: range },
  { label: "Views / Plays", key: "views", format: range },
  {
    label: "Cost per Viewer",
    key: "cpv",
    format: (t) =>
      t
        .map((v: number) =>
          v.toLocaleString("en-US", { style: "currency", currency: "USD" })
        )
        .join(" - "),
  },
  {
    label: "Audience Engagement",
    key: "engagement_rate",
    format: (t) => t.map((v) => `${v}%`).join(" - "),
  },
];

const Slider = ({ value, onChange, options }) => {
  const points = options.map((o, i) => ({
    value: o.value,
    position: (i / (options.length - 1)) * 100,
  }));

  const point = points.find((p) => String(p.value) === String(value));

  return (
    <div className="budget-slider">
      <input
        type="range"
        value={point?.position || 0}
        min={0}
        max={100}
        onChange={(e) => {
          const pointDiffs = points.map((p) => ({
            point: p,
            diff: Math.abs(p.position - parseInt(e.target.value)),
          }));
          const closest = minBy(pointDiffs, "diff")?.point;

          onChange?.(closest?.value);
        }}
      />
      <div className="budget-slider-points">
        {points.map((p, i) => (
          <div
            className="budget-slider-point"
            style={{
              left: `calc(${p.position}% + (12px - (12px * ${p.position}) / 50)`,
            }}
            key={p.value}
          >
            <div
              className={classNames("budget-slider-dot", {
                invisible: point.value === p.value,
              })}
            />
            <div className="mt-3">
              ${p.value.toLocaleString()}
              {i === points.length - 1 ? "+" : ""}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const combineQueries = (a, b) => {
  return merge(a, b, { unavailable: a.unavailable || b.unavailable });
};

const faqs = [
  {
    title: "How accurate are the predictions for creators and content production?",
    body: "Our platform provides estimated ranges based on your campaign budget, goals, and creator types. While we strive to offer accurate predictions, actual participation and content may vary depending on your final creator selections and at the campaign package selection stage.",
  },

  {
    title: "How is audience size and engagement calculated?",
    body: "Audience size and engagement are estimated based on the ideal creator you are looking for, their historical performance, and your campaign goals. A more accurate prediction will be provided at the campaign package selection stage, after the creators have opted in.",
  },

  {
    title: "What are creators cost per viewer and cost per reach?",
    body: "The cost per viewer and cost per social media user reach are calculated using your campaign budget, targeted audience, and the types of creators you are looking for. The creator tiers and the scope of your campaign play key roles in determining these costs.",
  },
];

const Budget = () => {
  useTemplate2();
  useShowOnboardingNav();

  const queries = combineQueries(
    useCampaign(),
    useApiGet(`/campaigns/${campaignUuid()}/budget_options`, "budget_options")
  );

  const { campaign, budget_options } = queries;

  const formik = useFormikHandler({
    campaign,
    defaultValues: { budget: 0 },
    fields: ["budget"],
    validators: { budget: Yup.number().moreThan(0) },
  });

  if (queries.unavailable) {
    return queries.unavailable;
  }

  const { budget } = formik.values;
  const selectedOption = budget_options.find((o) => String(o.value) === String(budget));

  return (
    <div id="main" className="container pl-16 pr-16 mb-5">
      <CampaignInfo campaign={campaign} />

      <div className="row">
        <div className="col col-heading">
          <div className="text-center page-subhead mt-5">
            <h1 className="fs-64">What's your expected results?</h1>

            <h3 className="fw-300 mt-2">
              Select an ad spend budget to view the predicted outcome.
            </h3>
          </div>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit} className="form-container">
        <div className="row">
          <div className="col mx-5 mt-11" data-form-field="budget">
            <Slider
              options={budget_options}
              value={formik.values.budget || 0}
              onChange={(v) => formik.setFieldValue("budget", v)}
            />
            {formik.errors.budget && <div className="red">Select a budget</div>}

            {budget > 0 ? (
              <>
                <h3 className="text-center mt-9 mb-3 fw-light">
                  Expected Results for{" "}
                  <span id="selectedRange" className="text-success-emphasis fw-bold">
                    ${budget.toLocaleString()}
                  </span>
                </h3>
                <div className="mt-1">
                  {detailsSchema.map((detail, i) => (
                    <dl className="metric-card" key={i}>
                      <dt>
                        {detail.label}
                        <i className="bi bi-question-circle small ms-2" />
                      </dt>
                      <dd className="d-block">
                        {detail.format(selectedOption?.[detail.key])}
                      </dd>
                    </dl>
                  ))}
                </div>
              </>
            ) : (
              <h3 className="text-center mt-9 fw-300">
                Please select a budget with the slider above to view expected results
              </h3>
            )}
          </div>
        </div>

        <ActionButtons label="Continue to Review" />
      </form>

      <Faqs faqs={faqs} />
    </div>
  );
};

export default Budget;
