import { useFormik } from "formik";
import { pick } from "lodash";
import { FormEvent, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { apiPut } from "../../utils/api";
import { useCampaignDevTool } from "../../utils/dev_tools";
import { getCampaignSteps } from "./campaign_steps";

export const useFormikHandler = ({
  campaign,
  defaultValues,
  fields,
  validators,
}: {
  campaign: any;
  defaultValues?: any;
  fields?: string[];
  validators?: Yup.ObjectShape;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [validation, setValidation] = useState(false);

  const steps = getCampaignSteps();

  const uuid = campaign?.uuid;

  const currentBlankStep = campaign?.steps.find((s) => s.next);

  if (defaultValues && currentBlankStep?.key === steps.current) {
    campaign = defaultValues;
  }

  const formik = useFormik({
    initialValues: (fields ? pick(campaign, fields) : campaign) || {},
    enableReinitialize: true,
    validateOnChange: validation,
    validateOnBlur: validation,
    validationSchema: Yup.object().shape(validators || {}),
    onSubmit: (values) => {
      apiPut(`/campaigns/${uuid}`, { campaign: values }).then((res) => {
        if (searchParams.get("mode") === "edit") {
          navigate(`/campaigns/${uuid}/review`);
        } else {
          navigate(steps.next ? `/campaigns/${uuid}/${steps.next}` : "/campaigns");
        }
      });
    },
  });

  useCampaignDevTool(formik);

  const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
    if (!validation) {
      setValidation(true);
    }
    formik.handleSubmit(e);
  };

  return { ...formik, handleSubmit };
};
