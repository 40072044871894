import ActionButtons from "./action_buttons";
import CampaignInfo from "./steps_side_nav";
import { CheckboxGroup, SelectField, Validators } from "./fields";
import Header from "./header";
import { useCampaign } from "./utils";
import { useFormikHandler } from "./form_handler";
import Tooltip, { QuestionTooltip } from "./tooltip";
import { capitalize } from "lodash";

const contentTypes = {
  instagram: [
    "reel",
    "photo post",
    "carousel post",
    "video posts",
    "stories",
    "igtv",
    "live videos",
    "guides",
  ],
  tiktok: ["video post", "duets", "stitch", "live video", "photo carousel"],
  youtube: [
    "dedicated video",
    "integration video",
    "pre-roll",
    "mid-roll",
    "shorts",
    "livestream",
  ],
  linkedin: ["text post", "photo post", "video post", "article", "document post", "poll"],
  x: [
    "post",
    "photo post",
    "video post",
    "threads",
    "polls",
    "retweets",
    "quote posts",
    "live videos",
  ],
};

const contentRights = [
  {
    value: "organic social media usage",
    tip: `
      Gain permission to post or repost influencer content organically on your brand’s
      social media accounts for authentic audience engagement.
    `,
  },
  {
    value: "social media paid ads",
    tip: `
      Amplify influencer content with paid ads across social platforms to reach a targeted
      audience and drive conversions.
    `,
  },
  {
    value: "display advertising",
    tip: `
      Utilize influencer content in programmatic display ads across websites, increasing
      brand visibility to a broader online audience.
    `,
  },
  {
    value: "web usage",
    tip: `
      Feature influencer content directly on your website or in native ad placements to
      enhance brand storytelling and user experience.
    `,
  },
  {
    value: "streaming ads",
    tip: `
      Deliver ads across internet-connected platforms, including OTT services, reaching
      audiences on mobile, desktop, and connected TVs for a seamless, cross-device
      experience.
    `,
  },
].map((cr) => ({
  ...cr,
  label: (
    <>
      {capitalize(cr.value)}
      <QuestionTooltip tip={cr.tip} />
    </>
  ),
}));

const ContentTypes = () => {
  const { campaign, unavailable } = useCampaign();

  const formik = useFormikHandler({
    campaign,
    fields: ["content_types", "content_rights"],
    validators: {
      content_types: Validators.atLeastOneInGroup,
      content_rights: Validators.atLeastOneInGroup,
    },
  });

  if (unavailable) {
    return unavailable;
  }

  return (
    <div id="main" className="container">
      <Header percent={20} />
      <CampaignInfo campaign={campaign} />

      <div className="row">
        <div className="page-subhead mb-5">
          <h1>Content Types</h1>
        </div>

        <form onSubmit={formik.handleSubmit} className="form-container">
          <div>
            <div className="bold">Platform</div>
            <div>{capitalize(campaign.platform)}</div>
          </div>

          <CheckboxGroup
            label="Content Types"
            name="content_types"
            options={contentTypes[campaign.platform] || []}
            className="mt-4"
            formik={formik}
          />

          <CheckboxGroup
            label="Content Rights"
            sublabel={
              <>
                Select your desired content usage options. If you’re unsure, usage rights
                can be arranged after content creation at an additional cost. Locking in
                your usage rights now ensures perpetual use but may require allocating
                more of your ad spend budget to secure these rights upfront.
              </>
            }
            name="content_rights"
            options={contentRights}
            className="mt-4"
            formik={formik}
          />

          <ActionButtons />
        </form>
      </div>
    </div>
  );
};

export default ContentTypes;
