import { Link } from "react-router-dom";
import { apiDelete, usePageData } from "../../utils/api";
import { genericError } from "../../utils/errors";
import { clickPrimaryButton, useDevTool } from "../../utils/dev_tools";
import { EllipsisMenu } from "../../utils/ellipsis_menu";

const CampaignsIndex = () => {
  const { data, refetch, unavailable } = usePageData();

  useDevTool(() => clickPrimaryButton(".btn-xw-outline-secondary"));

  if (unavailable) {
    return unavailable;
  }

  const { campaigns } = data;

  const deleteCampaign = async (campaign) => {
    const result = await apiDelete(`/campaigns/${campaign.uuid}.json`);
    if (result.data.success) {
      refetch();
    } else {
      genericError();
    }
  };

  return (
    <div id="main" className="container">
      <div className="heading d-flex align-items-center mb-3 border-bottom">
        <h1 className="me-auto">Dashboard</h1>
        <div className="actions">
          <Link className="btn btn-sm btn-xw-outline-secondary" to="/campaigns/intro">
            <i className="bi bi-plus" /> Create new campaign
          </Link>
        </div>
      </div>

      {campaigns.length ? (
        <div className="row">
          <div className="col">
            <table className="table table-tiles align-middle">
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th className="w-250px">Deadline</th>
                  <th className="w-250px">Status</th>
                  <th className="w-150px text-end">Actions</th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {campaigns.map((campaign) => (
                  <tr key={campaign.uuid}>
                    <td>
                      <h5 className="mb-0">
                        <Link
                          to={
                            campaign.next_step
                              ? `/campaigns/${campaign.uuid}/${campaign.next_step}`
                              : `/campaigns/${campaign.uuid}`
                          }
                        >
                          {campaign.name || <div className="gray">(continue)</div>}
                        </Link>
                      </h5>
                      <p className="text-muted mb-0 small">{campaign.brand.name}</p>
                    </td>
                    <td></td>
                    <td>
                      <span className="badge badge-xw rounded-pill text-bg-warning">
                        {campaign.agency_status}
                      </span>

                      <div className="form-text d-none">
                        Xrossworld is sourcing creators
                      </div>
                    </td>
                    <td className="text-end">
                      <div className="dropdown">
                        <EllipsisMenu
                          actions={[
                            {
                              label: "Delete Campaign",
                              action: () => deleteCampaign(campaign),
                              className: "text-danger",
                            },
                          ]}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col">
            <div className="text-center mt-5 pt-5">
              <h2 className="fs-32 white">There are currently no campaigns setup.</h2>
              <p className="my-4 light-gray fs-20">
                Get started by creating a new campaign.
              </p>
              <a href="/campaigns/intro" className="btn btn-lg btn-xw-primary">
                <i className="bi bi-plus"></i> Create new campaign
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignsIndex;
