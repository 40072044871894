import { Dropdown } from "react-bootstrap";

export const EllipsisMenu = ({
  actions,
}: {
  actions: {
    label: string | JSX.Element;
    action?: () => void;
    className?: string;
    link: string;
  }[];
}) => {
  return (
    <Dropdown className="ellipsis-menu">
      <Dropdown.Toggle variant="" id="dropdown-basic">
        <i className="bi bi-three-dots-vertical" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {actions.map((action, i) => (
          <Dropdown.Item
            href={action.link}
            key={i}
            onClick={() => {
              action.action?.();
            }}
            className={action.className}
          >
            {action.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
