import Header from "./header";
import CampaignInfo from "./steps_side_nav";
import { useCampaign, useShowOnboardingNav } from "./utils";
import { CheckboxGroup, MultiSelect, RadioGroup, TextArea, Validators } from "./fields";
import ActionButtons from "./action_buttons";
import Tooltip from "./tooltip";
import { HelpLinkDrawer } from "../../utils/offcanvas";
import { useFormikHandler } from "./form_handler";

const ADD_ONS = [
  [
    "Link in bio",
    "Direct followers to a link provided in the influencer's bio for more information or purchase.",
  ],
  [
    "Amazon affiliate link",
    "Use a specific Amazon link to promote a product and earn a commission on sales.",
  ],
  [
    "Custom affiliate link",
    "Use a unique link to track sales or leads generated by the influencer.",
  ],
  [
    "Share promo code",
    "Provide a discount code that followers can use for a special offer or deal.",
  ],
  ["Follow us", "Encourage followers to follow the brand’s social media account."],
  ["Click the link", "Encourage followers to click on a link to learn more."],
  ["Sign up", "Direct followers to sign up for a newsletter, service, or event."],
  ["Download", "Prompt followers to download an app or a digital resource."],
  ["Watch now", "Encourage followers to watch a video or livestream."],
  ["TikTok Shop link", "Direct followers to a Tik Tok shop."],
  ["Visit website", "Prompt followers to visit the brand's website."],
  ["Enter giveaway", "Encourage followers to participate in a contest or giveaway."],
  ["DM for details", "Invite followers to send a direct message for more information."],
  ["Save this post", "Ask followers to save the post for future reference."],
  ["Share this post", "Ask followers to share this post with their friends."],
  ["Comment below", "Engage followers by asking them to comment on the post"],
  [
    "No CTA - Brand Awareness Only",
    "Focus solely on increasing brand visibility without any direct call-to-action.",
  ],
].map((o) => ({
  value: o[0],
  label: (
    <>
      {o[0]}
      <Tooltip className="link-like" tip={o[1]}>
        <i className="bi bi-question-circle small text-muted ml-1" />
      </Tooltip>
    </>
  ),
}));

const DISCLOSURES = [
  [
    "Paid partnership label",
    "A label provided by social media platforms to indicate a sponsored collaboration, visible to viewers.",
  ],
  [
    "Collaborator tag",
    "Tagging the brand or partner directly in the post or story, indicating a collaboration.",
  ],
  [
    "Disclosure in caption (i.e. #partner or #sponsored)",
    "Including a disclosure hashtag (e.g., #partner or #sponsored) in the post caption to indicate a paid partnership.",
  ],
  [
    "Verbal disclosure in video",
    "Verbally disclosing the partnership at the beginning or end of a video post.",
  ],
].map((o) => ({
  value: o[0],
  label: (
    <>
      {o[0]}
      <Tooltip className="link-like" tip={o[1]}>
        <i className="bi bi-question-circle small text-muted ml-1" />
      </Tooltip>
    </>
  ),
}));

const Guidelines = () => {
  const points = [
    "Do not use profanity",
    "Align with brand values",
    "Avoid controversy",
    "Use high-quality visuals",
    "Use clear, error-free captions",
    "Follow platform guidelines",
    "Include a clear CTA if required",
    "Obtain permission for all copyright materials",
    "Ensure authenticity and clarify",
    "Avoid overly promotionial language",
    "Use audience-friendly language",
    "Disclose sponsorships",
  ];
  return (
    <div>
      <div className="mb-4">
        We'll notify all eligible creators to abide by our general guidelines to
        participate in any campaign.
      </div>

      {points.map((p) => (
        <div key={p}>
          <i className="bi bi-check-lg green mr-2" />
          {p}
        </div>
      ))}
    </div>
  );
};

const CreatorBrief = () => {
  useShowOnboardingNav();
  const { campaign, unavailable } = useCampaign();

  const formik = useFormikHandler({
    campaign,
    fields: [
      "campaign_purposes",
      "talking_points",
      "hashtags",
      "required_actions",
      "add_ons",
      "partnership_disclosures",
      "additional_guidelines",
      "creator_sample",
      "additional_instructions",
      "creator_sample_return",
    ],
    validators: {
      campaign_purposes: Validators.atLeastOneInGroup,
      talking_points: Validators.required,
      hashtags: Validators.required,
      required_actions: Validators.required,
      add_ons: Validators.atLeastOneInGroup,
      partnership_disclosures: Validators.atLeastOneInGroup,
      additional_guidelines: Validators.required,
      creator_sample: Validators.requiredRadio,
      // additional_instructions: Validators.required,
      // creator_sample_return: Validators.requiredRadio,
    },
  });

  if (unavailable) {
    return unavailable;
  }

  return (
    <div id="main" className="container">
      <Header percent={65} />
      <CampaignInfo campaign={campaign} />

      <div className="row">
        <div className="col">
          <div className="page-subhead mb-4">
            <h1>Creator Brief</h1>
            <h2>Key info for your creators to know.</h2>
          </div>

          <form onSubmit={formik.handleSubmit} className="form-container">
            <label>Creator's objective</label>
            <div className="form-text">What does the creator need to do for success.</div>
            <MultiSelect
              options={[
                "Attend event",
                "Awareness",
                "Education",
                "Experiential",
                "Product demo",
                "Promotional",
                "Trend",
                "Unboxing",
              ]}
              name="campaign_purposes"
              formik={formik}
            />

            <TextArea
              label="Talking points"
              sublabel="What helper text do we need here?"
              name="talking_points"
              formik={formik}
              className="mt-4"
            />

            <TextArea
              label="Campaign hashtags"
              sublabel="Hashtags to include with the post"
              name="hashtags"
              className="mt-4"
              formik={formik}
            />

            <TextArea
              label="Required actions"
              sublabel="List any required actions the creator needs to take to start on the campaign"
              name="required_actions"
              placeholder="i.e. download an app, attend event."
              className="mt-4"
              formik={formik}
            />

            <CheckboxGroup
              label="Call to action"
              sublabel="Additional features you would like to be included by the creator"
              name="add_ons"
              options={ADD_ONS}
              className="mt-4"
              formik={formik}
            />

            <CheckboxGroup
              label="Partnership disclosures"
              className="mt-4"
              sublabel="How would you like the creators to disclose the paid partnership?"
              name="partnership_disclosures"
              options={DISCLOSURES}
              formik={formik}
            />

            <TextArea
              label="Guidelines"
              sublabel={
                <>
                  We've got you covered with our{" "}
                  <HelpLinkDrawer
                    title={"General Guidelines"}
                    text="general guidelines"
                    content={<Guidelines />}
                  />
                </>
              }
              name="additional_guidelines"
              formik={formik}
              className="mt-4"
            />

            <RadioGroup
              label="Will the Creators be receiving a product sample to demonstrate and complete this campaign?"
              name="creator_sample"
              options={["Yes", "No"]}
              formik={formik}
              className="mt-4"
            />

            {formik.values.creator_sample === "Yes" && (
              <>
                <TextArea
                  label="Product Preferences"
                  sublabel="What information do you need from creators to provide them with product"
                  name="additional_instructions"
                  placeholder="i.e. T-shirt size, select preferred product sample, or provide context for your post"
                  formik={formik}
                  className="mt-4"
                />
                <RadioGroup
                  label="Do you need this product back from the creator?"
                  name="creator_sample_return"
                  options={["Yes", "No"]}
                  formik={formik}
                  className="mt-4"
                />
              </>
            )}

            <ActionButtons />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreatorBrief;
