import { useFormik } from "formik";
import { useState } from "react";
import { apiDelete, apiPost, apiPut, useApiGet, usePageData } from "../../../utils/api";
import { Drawer } from "../../../utils/offcanvas";
import { SelectField, TextField } from "../../campaigns/fields";
import { toast } from "react-toastify";
import { genericError } from "../../../utils/errors";

const Form = ({ user, onSuccess, close }) => {
  const { data: agenciesData } = useApiGet("/admin/agencies");

  const formik = useFormik({
    initialValues: user,
    onSubmit: (values) => {
      let method, path;
      if (user.uuid) {
        [method, path] = [apiPut, `/admin/users/${user.uuid}`];
      } else {
        [method, path] = [apiPost, `/admin/users`];
      }
      method(path, { user: values }).then((res) => {
        if (res.data.success) {
          onSuccess();
          close();
          toast("Saved");
        } else {
          genericError();
        }
      });
    },
  });

  const deleteUser = () => {
    if (confirm("Delete user?")) {
      apiDelete(`/admin/users/${user.uuid}`).then((res) => {
        if (res.data.success) {
          onSuccess();
          close();
          toast("User was deleted.");
        } else {
          genericError();
        }
      });
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <TextField label="Name" name="name" formik={formik} className="col" />
          <TextField label="Email" name="email" formik={formik} className="col" />
        </div>
        <div className="row mt-3">
          <SelectField
            label="Role"
            name="role"
            options={["agency", "admin", "super_admin"]}
            formik={formik}
            className="col"
          />
          <SelectField
            label="Agency"
            name="agency_id"
            options={
              agenciesData?.agencies.map((a) => ({
                label: a.name,
                value: a.id,
              })) || []
            }
            formik={formik}
            className="col"
          />
        </div>

        <div className="mt-4 flex space-between">
          <div>
            <button type="button" className="btn btn-secondary" onClick={() => close()}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary ml-2">
              Save
            </button>
          </div>
          {user["deletable?"] && (
            <button className="btn-text red" type="button" onClick={deleteUser}>
              Delete
            </button>
          )}
        </div>
      </form>

      <hr />

      <ResetPassword user={user} />
    </>
  );
};

const ResetPassword = ({ user }) => {
  const [link, setLink] = useState();

  if (!user.uuid) {
    return null;
  }

  const generate = () => {
    apiPost(`/admin/users/${user.uuid}/reset_password`).then(({ data }) => {
      if (data.success) {
        toast("Reset password token was generated");
        setLink(data.path);
        navigator.clipboard.writeText(data.path);
        setTimeout(setLink, 3000);
      }
    });
  };

  return (
    <>
      {link ? (
        <>
          Link copied to clipboard
          <i className="bi bi-check ml-1" />
        </>
      ) : (
        <a className="pointer" onClick={generate}>
          Reset Password
        </a>
      )}
    </>
  );
};

const AdminUsersIndex = () => {
  const { data, unavailable, refetch } = usePageData();
  const [formObject, setFormObject] = useState<{ uuid?: string }>();

  if (unavailable) {
    return unavailable;
  }

  const { users } = data;

  return (
    <div id="main">
      <div className="flex space-between">
        <h2 className="bold fs-2_5r">Manage Users</h2>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => {
            setFormObject({});
          }}
        >
          Add User
        </button>
      </div>

      <table className="table table-striped table-sm mt-4">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Agency</th>
            <th>Campaigns</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map((c) => (
            <tr key={c.uuid}>
              <td>{c.name}</td>
              <td>{c.email}</td>
              <td>{c.role}</td>
              <td>{c.agency?.name}</td>
              <td>{c.campaigns.length}</td>
              <td>
                <button
                  className="btn btn-text link-color"
                  onClick={() => {
                    setFormObject(c);
                  }}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Drawer
        show={!!formObject}
        onHide={() => setFormObject(undefined)}
        title={`${formObject?.uuid ? "Edit" : "Add"} User`}
      >
        <Form user={formObject} onSuccess={refetch} close={setFormObject} />
      </Drawer>
    </div>
  );
};

export default AdminUsersIndex;
