import { Link } from "react-router-dom";
import { SelectField, TextArea } from "./fields";
import { useCampaign } from "./utils";
import { useFormik } from "formik";

const PackageSelected = () => {
  const { campaign, unavailable } = useCampaign();
  const formik = useFormik({ initialValues: {}, onSubmit: () => {} });

  if (!campaign) {
    return unavailable;
  }

  return (
    <form onSubmit={formik.handleSubmit} name="campaign_form" className="container">
      <div className="row mb-5">
        <div className="col">
          <div className="heading d-flex align-items-center mb-3 border-bottom">
            <h1 className="me-auto">Campaign Packages</h1>
            <div className="actions">
              <h2 className="countdown text-danger fw-bolder d-flex align-items-end mb-0"></h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-8">
          <div className="page-subhead mb-5">
            <h2>Instructions for creators</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Lectus mauris ultrices
              eros in.
            </p>
          </div>

          <TextArea
            label="Content idea"
            name="content_idea"
            className="mt-4"
            formik={formik}
          />

          <TextArea
            label="Product description"
            name="product_description"
            className="mt-4"
            formik={formik}
          />

          <TextArea
            label="Product features to focus on"
            name="product_features_to_focus_on"
            className="mt-4"
            formik={formik}
          />

          <TextArea
            label="Special instructions/requirements"
            name="special_instructions_requirements"
            className="mt-4"
            formik={formik}
          />

          <SelectField
            label="Product shipment"
            name="product_shipment"
            options={["FedEx, USPS, UPS", "Online with code", "In-person at event"]}
            className="mt-4"
            formik={formik}
          />

          <div className="page-actions">
            <div>
              <Link
                className="btn btn-xw-secondary btn-lg"
                to={`/campaigns/${campaign.uuid}`}
              >
                Back
              </Link>
              <button className="btn btn-xw-primary btn-lg" type="button">
                Review and Pay
              </button>
            </div>
            <div className="mt-4">
              <Link to="/campaigns">Save and Exit</Link>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PackageSelected;
