import { Accordion } from "react-bootstrap";

const Faqs = ({ faqs }: { faqs: { title: string; body: string }[] }) => (
  <div className="wrapper-faq mt-18 ">
    <h6 className="mb-4 fw-normal text-center">Frequently Asked Questions</h6>

    <Accordion>
      {faqs.map((faq, i) => (
        <Accordion.Item eventKey={String(i)} key={i}>
          <Accordion.Header>{faq.title}</Accordion.Header>
          <Accordion.Body>{faq.body}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  </div>
);

export default Faqs;
