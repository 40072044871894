import { useFormik } from "formik";
import { apiDelete, apiPost, apiPut, useApiGet } from "../../../utils/api";
import {
  CheckboxField,
  DateField,
  MultiSelect,
  SelectField,
  TextField,
} from "../../campaigns/fields";
import { toast } from "react-toastify";
import { categories, countries, ethnicities, genders } from "../../campaigns/lists";
import { Drawer } from "../../../utils/offcanvas";

const Form = ({ creator, onSuccess, close }) => {
  if (creator === undefined) {
    return null;
  }

  let data, unavailable;

  if (creator.uuid) {
    ({ data, unavailable } = useApiGet(`/admin/creators/${creator.uuid}`));
  }

  const formik = useFormik({
    initialValues: data?.creator || { creator_platforms_attributes: [{}] },
    enableReinitialize: true,
    onSubmit: (values) => {
      let method: typeof apiPut | typeof apiDelete, path: string;

      if (creator.uuid) {
        [method, path] = [apiPut, `/admin/creators/${creator.uuid}`];
      } else {
        [method, path] = [apiPost, `/admin/creators`];
      }
      method(path, { creator: values }).then(({ data }) => {
        if (data.success) {
          onSuccess();
          close();
          toast("Saved");
        }
      });
      formik.setValues({ creator_platforms_attributes: [{}] });
    },
  });

  if (unavailable) {
    return unavailable;
  }

  return (
    <Drawer
      show={!!creator}
      onHide={() => close()}
      title={`${creator?.uuid ? "Edit" : "Add"} Creator`}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <TextField label="First Name" name="first_name" formik={formik} />
          <TextField label="Last Name" name="last_name" formik={formik} />
        </div>
        <div className="row mt-3">
          <DateField label="Birthdate" name="birthdate" formik={formik} />
          <SelectField label="Gender" name="gender" options={genders} formik={formik} />
        </div>
        <div className="row mt-3">
          <SelectField
            label="Ethnicity"
            name="ethnicity"
            options={ethnicities}
            formik={formik}
          />
          <TextField label="Email" name="email" formik={formik} />
        </div>
        <div className="row mt-3">
          <TextField label="Phone" name="phone" formik={formik} />
          <TextField label="City" name="city" formik={formik} />
        </div>
        <div className="row mt-3">
          <TextField label="State" name="state" formik={formik} />
          <TextField label="Zip Code" name="zip_code" formik={formik} />
        </div>
        <div className="row mt-3">
          <SelectField
            label="Country"
            name="country"
            options={countries}
            formik={formik}
          />
          <div className="col" />
        </div>
        <div className="row mt-3">
          <MultiSelect
            label="Categories"
            name="categories"
            options={categories}
            theme="light"
            closeMenuOnSelect
            formik={formik}
          />
        </div>
        <div className="row mt-3">
          <div className="col">
            <CheckboxField label="Deactivated" name="deactivated" formik={formik} />
          </div>
        </div>

        {creator.id && <div className="mt-2 gray">id: {creator.id}</div>}

        <div className="mt-4">
          <button type="button" className="btn btn-secondary" onClick={() => close()}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary ml-2">
            Save
          </button>
        </div>
      </form>
    </Drawer>
  );
};

export default Form;
