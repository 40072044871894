import { useFormik } from "formik";
import { TextField } from "./fields";
import { apiPost } from "../../utils/api";
import { clickPrimaryButton, randomName, useDevTool } from "../../utils/dev_tools";

export const fillOutForm = (formik) => {
  formik.setValues({
    name: randomName(),
    subsidiary_name: "subsidiary_name",
    industry: "industry",
    website: "website",
    contact_name: "contact_name",
    contact_email: "contact_email",
    contact_job_title: "contact_job_title",
  });

  setTimeout(() => clickPrimaryButton("#brand-form button[type=submit]"), 500);
};

const AddBrand = ({
  onSuccess,
  close,
}: {
  onSuccess: (id: string) => void;
  close: () => void;
}) => {
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      apiPost(`/brands`, { brand: values }).then((res) => {
        onSuccess(res.data.id);
        close();
      });
    },
  });

  useDevTool(() => fillOutForm(formik));

  return (
    <form onSubmit={formik.handleSubmit} id="brand-form">
      <TextField label="Company Name" name="name" formik={formik} />
      <TextField
        label="Company Subsidiary Name (if applicable)"
        name="subsidiary_name"
        className="mt-3"
        formik={formik}
      />
      <TextField label="Industry" name="industry" className="mt-3" formik={formik} />
      <TextField
        label="Company Website"
        name="website"
        className="mt-3"
        formik={formik}
      />
      <TextField
        label="Contact Name"
        name="contact_name"
        className="mt-3"
        formik={formik}
      />
      <TextField
        label="Contact E-mail"
        name="contact_email"
        className="mt-3"
        formik={formik}
      />
      <TextField
        label="Contact Job Title"
        name="contact_job_title"
        className="mt-3"
        formik={formik}
      />

      <div className="mt-5 text-right">
        <button type="button" className="btn btn-outline-primary" onClick={close}>
          Close
        </button>
        <button type="submit" className="btn btn-primary ml-2">
          Save
        </button>
      </div>
    </form>
  );
};

export default AddBrand;
