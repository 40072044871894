import { capitalize } from "lodash";
import { useCampaign } from "./utils";
import { Link, useNavigate } from "react-router-dom";
import ActionButtons from "./action_buttons";
import Header from "./header";
import CampaignInfo from "./steps_side_nav";
import { clickPrimaryButton, useDevTool } from "../../utils/dev_tools";
import { apiPut } from "../../utils/api";

const formatArray = (a) => (
  <ul>
    {a.map((i) => (
      <li key={i}>{capitalize(i)}</li>
    ))}
  </ul>
);

const formatField = (value) => {
  if (Array.isArray(value)) {
    return formatArray(value);
  }
  if (typeof value === "string") {
    return capitalize(value);
  }
  if (typeof value === "number") {
    return value;
  }
};

const data = [
  {
    key: "content_types",
    title: "Platform",
    fields: {
      platform: { title: "Platform" },
      content_types: { title: "Content Types" },
      content_rights: { title: "Content Rights" },
    },
  },
  {
    key: "creator_profile",
    title: "Creator Profile",
    fields: {
      categories: { title: "Interests" },
      creator_locations: { title: "Creator location" },
      creator_gender: { title: "Gender" },
      creator_age_ranges: { title: "Age Range" },
    },
  },
  {
    key: "campaign_brief",
    title: "Campaign Brief",
    fields: {
      name: { title: "Campaign name" },
      brand: { title: "Brand", value: (c) => c.brand.name },
      objectives: { title: "Success metrics" },
      about: { title: "About the campaign" },
      posting_dates: {
        title: "Posting dates",
        value: (c) => `${c.posting_start_date} - ${c.posting_end_date}`,
      },
      posting_flexible: { title: "Flexible posting timeline" },
    },
  },
  {
    key: "creator_brief",
    title: "Creative Brief",
    fields: {
      campaign_purposes: { title: "Campaign purpose" },
      talking_points: { title: "Talking points" },
      hashtags: {
        title: "Campaign hashtags",
      },
      required_actions: { title: "Required actions" },
      add_ons: {
        title: "Call to action",
      },
      partnership_disclosures: {
        title: "Partnership disclosures",
      },
      guidelines: {
        title: "Guidelines",
      },
      creator_sample: {
        title: "Sample product will be sent to creator",
      },
      additional_instructions: { title: "Product preferences" },
      creator_sample_return: { title: "Creator can keep demo product" },
    },
  },
  {
    key: "budget",
    title: "Budget",
    fields: {
      budget: {
        title: "Budget",
        value: (c) => c.budget && `$${parseInt(c.budget).toLocaleString()}`,
      },
    },
  },
];

const Review = () => {
  useDevTool(clickPrimaryButton);
  const { campaign, unavailable } = useCampaign();
  const uuid = campaign?.uuid;
  const navigate = useNavigate();

  const submit = (e) => {
    e.preventDefault();
    apiPut(`/campaigns/${uuid}`, { campaign: { submitted: true } }).then(({ data }) => {
      if (data.success) {
        navigate(`/campaigns`);
      }
    });
  };

  if (!campaign) {
    return unavailable;
  }

  return (
    <div id="main" className="container">
      <Header percent={100} />
      <CampaignInfo campaign={campaign} />

      <div className="page-subhead mb-5">
        <h1>Review and Submit</h1>
        <h2>
          Review the information you've provided below before submission. Please make sure
          all data is accurate since we'll start connecting with creators after you
          submit.
        </h2>
      </div>

      <form onSubmit={(e) => submit(e)} className="form-container">
        {data.map((group) => (
          <div key={group.key}>
            <div className="card">
              <div className="card-header pt-3">
                <h5 className="card-title d-inline-block">{group.title}</h5>
                <div className="float-end">
                  <Link to={`/campaigns/${uuid}/${group.key}?mode=edit`}>
                    <i className="bi bi-pencil"></i> Edit
                  </Link>
                </div>
              </div>
              <ul className="list-group list-group-flush">
                {Object.entries(group.fields).map(([name, fieldInfo]) => (
                  <li className="list-group-item border-0" key={name}>
                    <h6>{fieldInfo.title}</h6>
                    <div>
                      {(fieldInfo as { value: (c: any) => string }).value?.(campaign) ||
                        formatField(campaign[name])}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}

        <ActionButtons label="Complete" />
      </form>
    </div>
  );
};

export default Review;
