import { useState } from "react";
import { Drawer } from "../../../utils/offcanvas";
import { EllipsisMenu } from "../../../utils/ellipsis_menu";
import { usePageData } from "../../../utils/api";
import { clickPrimaryButton, useDevTool } from "../../../utils/dev_tools";
import PackageForm from "./package_form";

const dataPoints: [string, (c: any) => string][] = [
  ["Platform", (c) => c.platform],
  ["Interest categories", (c) => c.categories],
  ["Creator locations", (c) => c.creator_locations],
  ["Creator gender", (c) => c.creator_gender],
  ["Creator age ranges", (c) => c.creator_age_ranges],
  ["Agency", (c) => c.agency?.name],
  ["Brand", (c) => c.brand?.name],
  ["Objectives", (c) => c.objectives],
  ["About", (c) => c.about],
  ["Posting start date", (c) => c.posting_start_date],
  ["Posting end date", (c) => c.posting_end_date],
  ["Campaign purposes", (c) => c.campaign_purposes],
  ["Talking points", (c) => c.talking_points],
  ["Hashtags", (c) => c.hashtags],
  ["Required actions", (c) => c.required_actions],
  ["Add ons", (c) => c.add_ons],
  ["Partnership disclosures", (c) => c.partnership_disclosures],
  ["Additional guidelines", (c) => c.additional_guidelines],
  ["Creator sample", (c) => c.creator_sample],
  ["Creator sample return", (c) => c.creator_sample_return],
  ["Budget", (c) => c.budget],
  ["Created by", (c) => c.user.name],
  ["Created", (c) => new Date(c.created_at).toLocaleString()],
];

const AdminCampaignsShow = () => {
  const { data, unavailable, refetch } = usePageData();
  const [formObject, setFormObject] = useState<{ [key: string]: string }>();

  useDevTool(clickPrimaryButton);

  if (unavailable) {
    return unavailable;
  }

  const { campaign } = data;

  return (
    <div id="main" className="pb-10">
      <h1 className="mb-4">{campaign.name}</h1>

      <table className="table table-sm">
        <tbody>
          {dataPoints.map(([label, value]) => (
            <tr key={label}>
              <td className="bold">{label}</td>
              <td className="pl-5">
                {(() => {
                  const v = value(campaign);
                  if (v?.length === 0) {
                    return "--";
                  } else if (Array.isArray(v)) {
                    return v.map((i) => (
                      <span className="mh-3" key={i}>
                        ● {i}
                      </span>
                    ));
                  } else {
                    return v;
                  }
                })()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <a href={`/admin/creators?campaignId=${campaign.uuid}`} target="_blank">
        Search Creators
      </a>

      <div className="flex space-between mt-15">
        <h2>Packages</h2>

        <button
          className="btn btn-primary btn-sm"
          onClick={() => {
            setFormObject({});
          }}
        >
          Add Package
        </button>
      </div>

      <table className="table mt-2">
        <thead>
          <tr>
            <th className="w-100px fs-13" />
            <th className="w-100px fs-13 text-right">Cost</th>
            <th className="w-100px fs-13 text-right">UGC</th>
            <th className="w-100px fs-13 text-right">Posts</th>
            <th className="w-125px fs-13 text-right">Potential Plays</th>
            <th className="w-125px fs-13 text-right">Potential Engagement</th>
            <th className="w-125px fs-13 text-right">Internet User Cost</th>
            <th className="w-55px" />
            <th className="fs-13">Creators</th>
            <th className="w-50px" />
          </tr>
        </thead>
        <tbody>
          {campaign.packages?.map((p, i) => (
            <tr key={p.uuid}>
              <td>Package {i + 1}</td>
              <td className="text-right">{p.cost}</td>
              <td className="text-right">{p.ugc_count}</td>
              <td className="text-right">{p.posts_count}</td>
              <td className="text-right">{p.potential_plays}</td>
              <td className="text-right">{p.potential_engagement}</td>
              <td className="text-right">{p.internet_user_cost}</td>
              <td />
              <td>
                {p.creator_platforms.map((p) => (
                  <div key={p.uuid}>
                    <i className={`bi bi-${p.platform} mr-1`} />
                    {p.handle}
                  </div>
                ))}
              </td>
              <td>
                <EllipsisMenu
                  actions={[
                    {
                      label: "Edit",
                      action: () => setFormObject(p),
                    },
                    {
                      label: "Copy",
                      action: () => setFormObject({ ...p, uuid: null }),
                    },
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Drawer
        show={!!formObject}
        onHide={() => setFormObject(undefined)}
        title={`${formObject?.uuid ? "Edit" : "Add"} Package`}
      >
        <PackageForm
          object={formObject}
          close={() => setFormObject(undefined)}
          onSuccess={refetch}
          campaign={campaign}
        />
      </Drawer>
    </div>
  );
};

export default AdminCampaignsShow;
