import { useFormik } from "formik";
import { apiDelete, apiPost, apiPut, useApiGet } from "../../../utils/api";
import { MultiSelect, SelectField, TextField } from "../../campaigns/fields";
import { toast } from "react-toastify";
import { categories, platforms } from "../../campaigns/lists";
import { Drawer } from "../../../utils/offcanvas";

const PlatformForm = ({ object, parent, onSuccess, close }) => {
  if (object === undefined) {
    return null;
  }

  let data, unavailable;

  const urlBase = `/admin/creators/${parent.uuid}/creator_platforms`;

  if (object.uuid) {
    ({ data, unavailable } = useApiGet(`${urlBase}/${object.uuid}`));
  }

  const formik = useFormik({
    initialValues: data?.creator_platform || {},
    enableReinitialize: true,
    onSubmit: (values) => {
      let method: typeof apiPut | typeof apiDelete, path: string;

      if (object.uuid) {
        [method, path] = [apiPut, `${urlBase}/${object.uuid}`];
      } else {
        [method, path] = [apiPost, `${urlBase}`];
      }
      method(path, { creator_platform: values }).then(({ data }) => {
        if (data.success) {
          onSuccess();
          close();
          toast("Saved");
        }
      });
      formik.setValues({ object_platforms_attributes: [{}] });
    },
  });

  if (unavailable) {
    return unavailable;
  }

  return (
    <Drawer
      show={!!object}
      onHide={() => close()}
      title={`${object?.uuid ? "Edit" : "Add"} Handle`}
    >
      <form onSubmit={formik.handleSubmit}>
        <SelectField
          name="platform"
          label="Platform"
          options={platforms}
          formik={formik}
          required
        />

        <TextField
          label="Handle"
          name="handle"
          className="mt-4"
          prepend="@"
          formik={formik}
          required
        />

        <MultiSelect
          label="Categories"
          name="categories"
          options={categories}
          theme="light"
          closeMenuOnSelect
          className="mt-4"
          formik={formik}
        />

        <TextField label="Followers" name="followers" className="mt-4" formik={formik} />

        <TextField
          label="Impressions"
          name="impressions"
          className="mt-4"
          formik={formik}
        />

        <TextField
          label="Interactions"
          name="interactions"
          className="mt-4"
          formik={formik}
        />

        {object.id && <div className="mt-2 gray">id: {object.id}</div>}

        <div className="mt-4">
          <button type="button" className="btn btn-secondary" onClick={() => close()}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary ml-2">
            Save
          </button>
        </div>
      </form>
    </Drawer>
  );
};

export default PlatformForm;
