import { random, shuffle } from "lodash";

const sampleCreators = [1, 7, 8, 9, 10, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23];

export const fillOutPackageForm = (formik, setNewlyAddedCreators) => {
  const creatorIds = shuffle(sampleCreators).slice(0, random(1, 4));

  setNewlyAddedCreators(
    creatorIds.map((id) => ({ id, platform: "instagram", handle: `Creator ${id}` }))
  );

  formik.setValues({
    cost: `$${random(16, 200) * 500}`,
    ugc_count: random(4, 12),
    posts_count: random(4, 12),
    creator_platform_ids: creatorIds,
    potential_plays: (random(4, 12) * 10000).toLocaleString(),
    potential_engagement: `${random(2, 80) / 10}%`,
    internet_user_cost:
      (random(10, 30) / 2).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }) + "/user",
  });

  setTimeout(() => {
    (
      document.querySelector(
        "form[name=package-form] button[type=submit]"
      ) as HTMLButtonElement
    ).click();
  }, 500);
};
