import { useFormik } from "formik";
import { apiPut, usePageData } from "../../../utils/api";
import { TextField } from "../../campaigns/fields";
import { toast } from "react-toastify";

const SystemSettings = () => {
  const { data, unavailable, refetch } = usePageData();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data?.system_settings || {},
    onSubmit: (values) => {
      apiPut(`/admin/system_settings`, {
        system_settings: {
          notification_recipients: values.notification_recipients.split(","),
        },
      }).then((res) => {
        toast("Saved");
        refetch();
      });
    },
  });

  if (unavailable) {
    return unavailable;
  }

  return (
    <div>
      <h1>System Settings</h1>

      <form className="mt-5" onSubmit={formik.handleSubmit}>
        <div className="row">
          {Object.entries(data.system_settings).map(([name, value]) => (
            <div className="col-4" key={name}>
              <TextField label="Notification Recipients" name={name} formik={formik} />
            </div>
          ))}
        </div>
        <div className="row mt-5">
          <div className="col-1">
            <button className="btn btn-primary">Save</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SystemSettings;
