import { Link } from "react-router-dom";
import { usePageData } from "../../../utils/api";

const AdminHomeIndex = () => {
  const { data, unavailable } = usePageData();

  if (unavailable) {
    return unavailable;
  }

  const { campaigns } = data;

  return (
    <div id="main">
      <h2 className="fs-2_5r">Manage Campaigns</h2>

      <table className="table table-striped mt-4">
        <thead>
          <tr>
            <th>Campaign</th>
            <th>Brand</th>
            <th>Agency</th>
            <th>Stage</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map((c) => (
            <tr key={c.uuid}>
              <td>{c.name}</td>
              <td>{c.brand?.name}</td>
              <td>{c.agency.name}</td>
              <td>{c.stage}</td>
              <td>
                <Link to={`/admin/campaigns/${c.uuid}`}>View</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminHomeIndex;
