import { useNavigate } from "react-router-dom";
import { useTemplate2 } from "./utils";
import { clickPrimaryButton, useDevTool } from "../../utils/dev_tools";
import { apiPost } from "../../utils/api";
import Faqs from "./faq";

const steps = [
  {
    title: "Discover",
    body: "Tell us what type of creators you're looking for, and we'll qualify and contact them.",
    img: "icon-discover.png",
  },
  {
    title: "Negotiate",
    body: "We'll determine which cohorts of creators will be the most qualified to work on your campaign at the fairest rates.",
    img: "icon-handshake.png",
  },
  {
    title: "Choose",
    body: "Browse through creator packages and choose the one you'd like to purchase.",
    img: "icon-briefcase.png",
  },
];

const faqs = [
  {
    title: "How does Xrossworld match creators to my campaign?",
    body: "Our AI-powered platform analyzes your campaign goals, target audience, and budget to automatically match you with the most relevant creators. The selection process is data-driven, ensuring the creators align with your specific needs for maximum impact.",
  },
  {
    title: "Can I manually select creators for my campaign?",
    body: "No manual selection is required. Our AI selects the most suitable creators for your campaign based on your brief. You’ll receive a curated package of creators that best match your campaign objectives and audience.",
  },
  {
    title: "How are creator rates and agreements handled?",
    body: "Our AI negotiates the best rates with creators on your behalf, ensuring fair market value and transparency in all agreements. You don’t need to worry about negotiating terms—we handle everything for you.",
  },
];

const CampaignsIntro = () => {
  useTemplate2();
  useDevTool(clickPrimaryButton);
  const navigate = useNavigate();

  const initiate = () => {
    apiPost("/campaigns").then(({ data }) => {
      if (data.uuid) {
        navigate(`/campaigns/${data.uuid}/platform`);
      }
    });
  };

  return (
    <div id="main" className="container container-narrow">
      <div className="row">
        <div className="col">
          <div className="text-center mt-5">
            <h1 className="fs-46">
              We <strong>discover</strong>, <strong>negotiate</strong> &amp;{" "}
              <strong>manage</strong> your creators.
            </h1>
          </div>

          <div className="wrapper py-3">
            <h4 className="mt-5 mb-4 text-center">How it Works</h4>

            <div className="row row-cols-1 row-cols-md-3 g-4">
              {steps.map((step) => (
                <div className="col" key={step.title}>
                  <div className="card h-100">
                    <div className="card-body">
                      <img
                        src={`/images/${step.img}`}
                        className="img-fluid"
                        width={283}
                      />
                      <h4 className="card-title text-center mt-4">{step.title}</h4>
                      <p>{step.body}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="page-actions mt-5">
            <button className="btn btn-xw-primary btn-lg" onClick={initiate}>
              Discover your perfect creators
            </button>
          </div>

          <Faqs faqs={faqs} />
        </div>
      </div>
    </div>
  );
};

export default CampaignsIntro;
