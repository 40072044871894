import { Drawer } from "../../utils/offcanvas";

const Header = ({ percent }) => (
  <>
    <Drawer
      trigger={
        <>
          <a className="sidebar-open" role="button">
            <i className="bi bi-list-ul"></i>
          </a>
        </>
      }
      placement="start"
    >
      Seide Nav
    </Drawer>
    <div className="row mb-5">
      <div className="col">
        <div className="progress" role="progressbar" style={{ height: 8 }}>
          <div className="progress-bar" style={{ width: `${percent}%` }}></div>
        </div>
      </div>
    </div>
  </>
);

export default Header;
