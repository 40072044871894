import classNames from "classnames";
import { clamp } from "lodash";

const PaginationBar = ({
  meta,
  onChange,
  isFetching,
}: {
  meta: {
    page: number;
    pages: number;
    count: number;
  };
  onChange: (f: number) => void;
  isFetching?: boolean;
}) => {
  const Control = ({ icon, page, disabled }) => {
    return (
      <i
        className={classNames(`bi bi-chevron-${icon}`, {
          "light-gray": disabled,
          pointer: !disabled,
        })}
        onClick={() => onChange(clamp(page, 1, meta.pages))}
      />
    );
  };

  const { page, pages, count } = meta;

  return (
    <div className="flex space-between">
      <div>Results: {count}</div>

      {isFetching && <i className="bi bi-three-dots" />}

      <div>
        <Control icon="bar-left" page={1} disabled={page === 1} />
        <Control icon="double-left" page={page - 10} disabled={page === 1} />
        <Control icon="left" page={page - 1} disabled={page === 1} />

        <span className="inline-block w-50px text-center">{page}</span>

        <Control icon="right" page={page + 1} disabled={page === pages} />
        <Control icon="double-right" page={page + 10} disabled={page === pages} />
        <Control icon="bar-right" page={pages} disabled={page === pages} />
      </div>
    </div>
  );
};

export default PaginationBar;
